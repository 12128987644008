<template>
  <div v-if="useFeatureState('bt-dev-debug')?.value == true">
    Color mode: {{ $colorMode.value }}
    Color Prop: {{ defaultColorMode }}

    <!-- <select v-model="$colorMode.preference"> -->
    <!-- <option value="system">System</option> -->
    <!-- <option value="light">Light</option>
      <option value="dark">Dark</option> -->
    <!-- <option value="blackweek">Black Week 2024</option> -->
    <!-- </select> -->
  </div>
  <div v-else></div>
</template>

<script setup lang="ts">
const props = defineProps({
  defaultColorMode: {
    type: String,
    default: 'blackweek'
  }
})
const colorMode = useColorMode()
colorMode.value = props?.defaultColorMode
colorMode.preference = props?.defaultColorMode
</script>

<style>

.blackweek-mode .header{
  --paragraph-light: white;
  --paragraph: rgb(255, 219, 219);
  --color-secondary: dark-grey;
}


/* 
.dark-mode body {
  background-color: #00040D;
  color: white;
} */

/*
.dark-mode .former-price {
  color: white;
}

.dark-mode .theme-price {
  --paragraph-light: white;
  --paragraph: white;
  --color-secondary: dark-grey;
}

.dark-mode .ais-SortBy-select {
  background-color: #00040D;
  color: white;
}

.dark-mode .megamenu-container div {
  background-color: #00040D;
} */

/* .dark-mode .store-logo-container {
    fill: white;
  } */
/* .dark-mode .store-logo-container a { */
/* filter:hue-rotate(180deg);     */
/* } 
.dark-mode .store-logo-container img {

  background-color: white;
}

.dark-mode header {
  background-color: #00040D;
  color: white;
}

.dark-mode a {
  color: white;
}

.dark-mode header div {
  color: white;
}
*/

.blackweek-mode .local-store,
.blackweek-mode .useraccount {
  background-color: #00040D;
}

.blue-schimmel-cheese-mode .megamenu-container div,
.blue-schimmel-cheese-mode .storefinder,
.blackweek-mode .megamenu-container div, .blackweek-mode .storefinder {
  /* background-color: #00040D; */
  color: black;
}

.blue-schimmel-cheese-mode .header {
  --paragraph-light: white;
  --paragraph: rgb(255, 219, 219);
  --color-secondary: dark-grey;
}

.blue-schimmel-cheese-mode header,
.blue-schimmel-cheese-mode .sticky-header {
  color: white;
  background-color: #004899;
}

.blue-schimmel-cheese-mode .shoppingcart,
.blue-schimmel-cheese-mode .store-name {
  color: black;
  background-color: white;
}

.blue-schimmel-cheese-mode .useraccount,
.blue-schimmel-cheese-mode .flyout,
.blue-schimmel-cheese-mode .local-store {
  background-color: white;
  color: black;
}

.blackweek-mode header .shoppingcart,
.store-name {
  color: white;
  background-color: #00040D;
}

.blackweek-mode .useraccount,
 .blackweek-mode .flyout,
  .blackweek-mode .local-store {
  background-color: white;
  color: black;
}

.blackweek-mode header {
  background-color: #00040D;
  color: white;
}

.blackweek-mode .sticky-header {
  background-color: #00040D;
  color: black;
}

.blackweek-mode .UserAccountButton,
.blackweek-mode .LocalStoreButton {
  color: white;
}

.blackweek-mode .super-navbar .super-navbar-nav>.nav-item {
  background-color: #00040D;
  border-color: white;
  color: white;
}
</style>